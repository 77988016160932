import Vue from "vue";
import Router from "vue-router";
import store from "./store";
import axios from "axios";

Vue.use(Router);
let router = new Router({
    mode: "history",
    routes: [{
            path: "/",
            props: true,
            component: {
                render(c) {
                    return c("router-view");
                },
            },
            meta: {
                requiresAuth: false,
            },
            children: [{
                    path: "",
                    name: "Home",
                    meta: {
                        requiresAuth: false,
                    },
                    component: () =>
                        import ("./components/Common/appHome"),
                },
                {
                    path: "Login",
                    name: "Login",
                    props: true,
                    component: () =>
                        import ("./components/Common/appLogin"),
                    meta: {
                        requiresAuth: false,
                    },
                },
                // {
                // 	path: 'SignUp',
                // 	name: 'SignUp',
                // 	props: true,
                // 	component: () => import('./components/Common/appSignUp'),
                // 	meta: {
                // 		requiresAuth: false,
                // 	}
                // },
                {
                    path: "ForgotPassword",
                    name: "ForgotPassword",
                    props: true,
                    component: () =>
                        import ("./components/Common/appForgotPassword"),
                    meta: {
                        requiresAuth: false,
                    },
                },
                {
                    path: "/",
                    component: () =>
                        import ("./components/Common/appDashboard"),
                    meta: {
                        requiresAuth: true,
                    },
                    children: [{
                        path: "Profile",
                        name: "Profile",
                        component: () =>
                            import ("./components/Profile/profile"),
                        meta: {
                            requiresAuth: true,
                        },
                    }, ],
                },
            ],
        },
        {
            path: "/Admin",
            component: () =>
                import ("./components/Common/appDashboard"),
            meta: {
                requiresAuth: true,
            },
            children: [{
                    path: "",
                    name: "AdminDashboard",
                    component: () =>
                        import ("./components/Admin/Dashboard/dashboard"),
                },
                {
                    path: "/Admins",
                    name: "Admins",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./components/Admin/Admins/AdminMajor"),
                },

                {
                    path: "/add",
                    name: "adds",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./components/Admin/Admins/addMenu"),
                },

                {
                    path: "/naturalists",
                    name: "naturalists",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./components/Admin/Naturalists/naturalists"),
                },

                {
                    path: "/Analytics",
                    name: "Analytics",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./components/Admin/Analytics/AnalyticsDashboard"),
                },
                {
                    path: "/dosAndDonts",
                    name: "dosAndDonts",
                    component: () =>
                        import ("./components/Admin/Instructions/dosAndDonts"),
                },
                {
                    path: "/generalInstruction",
                    name: "generalInstruction",
                    component: () =>
                        import ("./components/Admin/Instructions/generalInstruction"),
                },
                {
                    path: "/Transactions",
                    name: "Report",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./components/Admin/Transactions/report"),
                },
                {
                    path: "/PaymentReport",
                    name: "PaymentReport",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./components/Admin/Transactions/Payment/payReport"),
                },
                {
                    path: "Holidays",
                    name: "Holidays",
                    component: () =>
                        import ("./components/Admin/Holidays/holidays"),
                },
                {
                    path: "Category",
                    name: "Category",
                    component: () =>
                        import ("./components/Admin/Categories/category"),
                },

                {
                    path: "accomodationCategory",
                    name: "accomodationCategory",
                    component: () =>
                        import ("./components/Admin/AccomodationCategory/accomodationCategory"),
                },
                {
                    path: "/SubCategory",
                    name: "SubCategory",
                    component: () =>
                        import ("./components/Admin/SubCategory/SubCategory"),
                },
                {
                    path: "GuestList",
                    name: "GuestList",
                    component: () =>
                        import ("./components/Admin/Guest/guestList"),
                },
                {
                    path: "UserList",
                    name: "UserList",
                    component: () =>
                        import ("./components/Admin/Users/userList"),
                },
                {
                    path: "UserReport",
                    name: "UserReport",
                    component: () =>
                        import ("./components/Admin/Users/Report/report"),
                },
                {
                    path: "UserConsolidate",
                    name: "UserConsolidate",
                    component: () =>
                        import ("./components/Admin/Users/Consolidate/consolidate"),
                },

                {
                    path: "/Feedback",
                    name: "Feedback",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./components/Admin/Feedback/feedback"),
                },
                {
                    path: "/FeedbackView",
                    name: "FeedbackView",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./components/Admin/Feedback/feedbackView"),
                },

                {
                    path: "addClosed",
                    name: "addClosed",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./components/Admin/Programs/closedown/addClosed"),
                },
                {
                    path: "editClosed",
                    name: "editClosed",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./components/Admin/Programs/closedown/editClosed"),
                },
                {
                    path: "viewClosed",
                    name: "viewClosed",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./components/Admin/Programs/closedown/viewClosed"),
                },
                {
                    path: "Programs/Add",
                    name: "AddProgram",
                    component: () =>
                        import ("./components/Admin/Programs/addProgram"),
                },
                {
                    path: "Cottages/Add",
                    name: "AddCottage",
                    component: () =>
                        import ("./components/Admin/Programs/Cottage/addCottage"),
                },
                {
                    path: "/addPackage/:programId/:isAddMore",
                    name: "AddPackage",
                    props: true,
                    component: () =>
                        import ("./components/Admin/Programs/Package/addPackage"),
                },
                {
                    path: "/addSlot/:programId",
                    name: "AddSlot",
                    props: true,
                    component: () =>
                        import ("./components/Admin/Programs/Slots/addSlot"),
                },
                {
                    path: "/EditProgram/:id",
                    name: "EditProgram",
                    component: () =>
                        import ("./components/Admin/Programs/editProgram"),
                    props: true,
                },
                {
                    path: "/EditPackage/:id/:progId",
                    name: "EditPackage",
                    component: () =>
                        import ("./components/Admin/Programs/Package/editPackage"),
                    props: true,
                },
                {
                    path: "/EditSlot/:id/:programId",
                    name: "EditSlot",
                    component: () =>
                        import ("./components/Admin/Programs/Slots/editSlot"),
                    props: true,
                },
                {
                    path: "Programs",
                    name: "Programs",
                    component: () =>
                        import ("./components/Admin/Programs/programs"),
                },
                {
                    path: "Cottage",
                    name: "Cottage",
                    component: () =>
                        import ("./components/Admin/Programs/Cottage/cottage"),
                },

                {
                    path: "IB",
                    name: "IB",
                    component: () =>
                        import ("./components/Admin/Programs/IB/cottage"),
                },

                // IB closedown 
                {
                    path: "addIBClosed",
                    name: "addIBClosed",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./components/Admin/Programs/IBclosedown/addClosed"),
                },
                {
                    path: "editIBClosed",
                    name: "editIBClosed",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./components/Admin/Programs/IBclosedown/editClosed"),
                },
                {
                    path: "viewIBClosed",
                    name: "viewIBClosed",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./components/Admin/Programs/IBclosedown/viewClosed"),
                },

                // Asset 
                {
                    path: "addUnits",
                    name: "addUnits",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./components/Admin/AssetMgmt/addUnit"),
                },
                {
                    path: "viewUnits",
                    name: "viewUnits",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./components/Admin/AssetMgmt/viewUnits"),
                },

                {
                    path: "Unit",
                    name: "Unit",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./components/Admin/AssetMgmt/unit"),
                },
                {
                    path: "editUnit",
                    name: "editUnit",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./components/Admin/AssetMgmt/editUnit"),
                },
                // Bus 
                {
                    path: "addBus",
                    name: "addBus",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./components/Admin/AssetMgmt/Bus/addBus"),
                },
                {
                    path: "viewBus",
                    name: "viewBus",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./components/Admin/AssetMgmt/Bus/viewBus"),
                },
                {
                    path: "editBus",
                    name: "editBus",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./components/Admin/AssetMgmt/Bus/editBus"),
                },

                {
                    path: "Employee",
                    name: "Employee",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./components/Admin/AssetMgmt/Employee/empList"),
                },
                {
                    path: "addEmployee",
                    name: "addEmployee",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./components/Admin/AssetMgmt/Employee/addEmp"),
                },
                {
                    path: "editEmployee",
                    name: "editEmployee",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./components/Admin/AssetMgmt/Employee/editEmp"),
                },
                {
                    path: "viewEmployee",
                    name: "viewEmployee",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./components/Admin/AssetMgmt/Employee/viewEmp"),
                },

                {
                    path: "markAttendance",
                    name: "markAttendance",
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import ("./components/Admin/AssetMgmt/Employee/markAttendance"),
                },

                // Assets end 



                // {
                //     path: 'GetInvolved',
                //     name: 'GetInvolved',
                //     component: () =>
                //         import ('./components/Admin/GetInvolved/getInvolved')
                // },
                {
                    path: "BookingList/:dateProp",
                    name: "BookingList",
                    component: () =>
                        import ("./components/Admin/Booking/bookingList"),
                    props: true,
                },
                {
                    path: "/ReschedulePage",
                    name: "ReschedulePage",
                    component: () =>
                        import ("./components/Admin/Reschedule/Booking/ReschedulePage"),
                    props: true,
                },
                {
                    path: "BookingReport",
                    name: "BookingReport",
                    component: () =>
                        import ("./components/Admin/BookingReport/bookingList"),
                    props: true,
                },
                {
                    path: "CottageBookingReport",
                    name: "CottageBookingReport",
                    component: () =>
                        import ("./components/Admin/BookingReport/cottageList"),
                    props: true,
                },

                {
                    path: "ReservationList/:dateProp",
                    name: "ReservationList",
                    component: () =>
                        import ("./components/Admin/ReservationsList/bookingList"),
                    props: true,
                },
                {
                    path: "IBReservationList/:dateProp",
                    name: "IBReservationList",
                    component: () =>
                        import ("./components/Admin/IBReservationsList/bookingList"),
                    props: true,
                },
                {
                    path: "/allStatic",
                    name: "allStatic",
                    component: () =>
                        import ("./components/Admin/staticData/allStatic"),
                    children: [{
                            path: "/learnAboutTheReserve",
                            name: "LearnAboutTheReserve",
                            component: () =>
                                import (
                                    "./components/Admin/LearnAboutTheReserve/learnAboutTheReserve"
                                ),
                            meta: {
                                requiresAuth: true,
                            },
                        },
                        {
                            path: "/viewStatic",
                            name: "viewStatic",
                            component: () =>
                                import ("./components/Admin/staticData/viewStatic"),
                            meta: {
                                requiresAuth: true,
                            },
                        },
                        // {
                        //   path: "/viewStatic",
                        //   name: "viewStatic",
                        //   component: () =>
                        //     import("./components/Admin/staticData/viewStatic"),
                        //   meta: {
                        //     requiresAuth: true,
                        //   },
                        // },
                        {
                            path: "GetInvolved",
                            name: "GetInvolved",
                            component: () =>
                                import ("./components/Admin/GetInvolved/getInvolved"),
                        },
                        {
                            path: "/getInvolvedStatic",
                            name: "GetInvolvedStatic",
                            component: () =>
                                import ("./components/Admin/GetInvolved/getInvolvedStatic"),
                            meta: {
                                requiresAuth: true,
                            },
                        },
                    ],
                },
                // {
                // 	path: '/reservation',
                // 	meta: {
                // 		requiresAuth: true,
                // 	},
                // 	component: {
                // 		render(c) { return c('router-view') }
                // 	},

                // 	children: [
                // 		{
                // 			path: '',
                // 			name: 'ReservationsList',
                // 			meta: {
                // 				requiresAuth: true,
                // 			},
                // 			component: () =>
                // 				import('./components/Admin/ReservationsList/bookingList'),
                // 			props: true,

                // 		},

                // 	],
                // },

                {
                    path: "Booking/PaymentDetails",
                    name: "PaymentDetails",
                    component: () =>
                        import ("./components/Admin/Booking/paymentDetails"),
                },
                {
                    path: "CancelledList",
                    name: "CancelledList",
                    component: () =>
                        import ("./components/Admin/Booking/cancelledList"),
                },
                {
                    path: "bookingreport",
                    name: "bookingreport",
                    component: () =>
                        import ("./components/Admin/Payment/bookingreport"),
                },
                {
                    path: "Notifications",
                    name: "Notifications",
                    component: () =>
                        import ("./components/Admin/Notifications/notifications"),
                },
                {
                    path: "staticComponent/:Name",
                    name: "staticComponent",
                    component: () =>
                        import ("./components/Admin/staticData/staticComponent"),
                    props: true,
                },
                {
                    path: "/editStatic/:Name",
                    name: "editStatic",
                    component: () =>
                        import ("./components/Admin/staticData/editStatic"),
                    props: true,
                },
                {
                    path: "/homeSlider",
                    name: "HomeSlider",
                    component: () =>
                        import ("./components/Admin/Home/homeSlider"),
                },
                {
                    path: "/GalleryPage",
                    name: "GalleryPage",
                    component: () =>
                        import ("./components/Admin/Home/GalleryPage"),
                },
                {
                    path: "/program/:id",
                    name: "Program",
                    component: () =>
                        import ("./components/Admin/Programs/program"),
                    props: true,
                },

                {
                    path: "/programIB/:id",
                    name: "ProgramIB",
                    component: () =>
                        import ("./components/Admin/Programs/IB/programIB"),
                    props: true,
                },
                {
                    path: "/addProduct",
                    name: "AddProduct",
                    component: () =>
                        import ("./components/Admin/Products/addProduct"),
                },
                {
                    path: "/products",
                    name: "Products",
                    component: () =>
                        import ("./components/Admin/Products/products"),
                },
                {
                    path: "/product/:id",
                    name: "Product",
                    component: () =>
                        import ("./components/Admin/Products/product"),
                    props: true,
                },
                {
                    path: "/editProduct/:id",
                    name: "EditProduct",
                    component: () =>
                        import ("./components/Admin/Products/editProduct"),
                    props: true,
                },
                {
                    path: "/packageRates/:id",
                    name: "PackageRates",
                    component: () =>
                        import ("./components/Admin/Programs/Package/packageRates"),
                    props: true,
                },
                {
                    path: "/slots/:id",
                    name: "Slots",
                    component: () =>
                        import ("./components/Admin/Programs/Slots/slots"),
                    props: true,
                },
                // {
                //     path: '/learnAboutTheReserve',
                //     name: 'LearnAboutTheReserve',
                //     component: () =>
                //         import ('./components/Admin/LearnAboutTheReserve/learnAboutTheReserve'),
                //     meta: {
                //         requiresAuth: true,
                //     },

                // },
                // {
                //     path: '/getInvolvedStatic',
                //     name: 'GetInvolvedStatic',
                //     component: () =>
                //         import ('./components/Admin/GetInvolved/getInvolvedStatic'),
                //     meta: {
                //         requiresAuth: true,
                //     },

                // },
                {
                    path: "Reservation",
                    meta: {
                        requiresAuth: false,
                    },
                    component: () =>
                        import ("./components/Admin/Reservation/Booking/availability"),
                    children: [{
                            path: "Availability",
                            name: "Availability",
                            meta: {
                                requiresAuth: false,
                            },
                            component: () =>
                                import ("./components/Admin/Reservation/Booking/availability"),
                        },
                        // {
                        //     path: 'Booking',
                        //     name: 'Booking',
                        //     meta: {
                        //         requiresAuth: true,
                        //     },
                        //     component: () =>
                        //         import('./components/Reservation/Booking/booking'),
                        // },
                        // {
                        //     path: 'BookingForm',
                        //     name: 'BookingForm',
                        //     meta: {
                        //         requiresAuth: true,
                        //     },
                        //     component: () =>
                        //         import('./components/Reservation/Booking/bookingForm'),
                        // },
                    ],
                },
                {
                    path: "ReservationIB",
                    meta: {
                        requiresAuth: false,
                    },
                    component: () =>
                        import ("./components/Admin/ReservationIB/Booking/availability"),
                    children: [{
                        path: "Availability",
                        name: "Availability",
                        meta: {
                            requiresAuth: false,
                        },
                        component: () =>
                            import ("./components/Admin/ReservationIB/Booking/availability"),
                    }, ],
                },

                {
                    path: "AddOffices",
                    name: "AddOffices",
                    component: () =>
                        import ("./components/Admin/Offices/addOffices"),
                },
                {
                    path: "purchaseReports",
                    name: "Purchases",
                    component: () =>
                        import ("./components/Admin/Reports/Products/purchase"),
                },

                {
                    path: "purchases",
                    name: "PurchasesNew",
                    component: () =>
                        import ("./components/Admin/Reports/Products/newpurchase"),
                },

                {
                    path: "checkpointOffline",
                    name: "checkpointOffline",
                    component: () =>
                        import ("./components/Admin/Reports/CheckPoint/cpReport"),
                },
                {
                    path: "attendanceReport",
                    name: "attendanceReport",
                    component: () =>
                        import ("./components/Admin/Reports/Attendance/attendanceReport"),
                },
            ],
        },

        {
            path: "/ServerError",
            name: "ServerError",
            props: true,
            component: () =>
                import ("./components/Common/500"),
            meta: {
                requiresAuth: false,
            },
        },
        {
            path: "*",
            name: "404PageNotFound",
            props: true,
            component: () =>
                import ("./components/Common/404"),
            meta: {
                requiresAuth: false,
            },
        },
    ],
    scrollBehavior() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
    },
});
router.beforeEach((to, from, next) => {
    if (
        to.matched.some((route) => route.meta.requiresAuth == true) &&
        store.state.isLoggedIn == false
    ) {
        console.log(from);
        next({ name: "Login", params: { lastPage: from } });
        return;
    }
    if (store.state.isLoggedIn == true) {
        axios({
                method: "POST",
                url: "/validate/token",
                headers: {
                    token: localStorage.getItem("token"),
                },
            })
            .then((response) => {
                if (!response.data.status) {
                    store.commit("sessionOut", true);
                    return;
                }
            })
            .catch((err) => {
                var msg = err;
                console.log(msg);
            });
    }
    if (
        (to.name == "Login" || to.name == "Home") &&
        store.state.isLoggedIn == true
    ) {
        next({ name: "AdminDashboard" });
        return;
    }
    next();
});

export default router;